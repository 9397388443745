import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './../modules/user/user.service';

@Component({
    selector: 'app-admin-gate',
    templateUrl: './admin-gate.component.html',
    styleUrls: ['./admin-gate.component.css']
})
export class AdminGateComponent implements OnInit {

    password = '';

    constructor(
        private router: Router,
        private userService: UserService) {

    }

    ngOnInit() {
    }

    savePassword(): void {
        this.userService.setAdminTestPass(this.password);
        this.password = '';
        this.router.navigate(['']);
    }
}
