import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { UserService } from './../modules/user/user.service';


@Injectable({
    providedIn: 'root'
})
export class AdminAccessGuard implements CanActivate {
    constructor(
        private router: Router,
        private userService: UserService
    ) {

    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.hasPassword(state);
    }

    hasPassword(state: RouterStateSnapshot) {
        return this.userService.canView$
            .pipe(take(1))
            .pipe(tap(canView => {
                if (canView) {
                    return of(true);
                }

                this.router.navigate(['gate']);
                return of(false);
            }));
    }
}
