import { Injectable } from '@angular/core';
import { version } from 'src/version.json';

@Injectable({
    providedIn: 'root'
})
export class EnvironmentService {
    version = version;

    constructor() { }

}
