import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { cloneDeep as clone } from 'lodash-es';
import { User } from './user';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private canView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    canView$: Observable<boolean> = this.canView.asObservable();

    private user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    user$: Observable<User> = this.user.asObservable().pipe(map(user => clone(user)));

    constructor() {
        this.updateCanView();
        // TODO: once proper auth happens, remove this:
        this.user.next({
            displayName: 'Doctor Ghost',
            email: 'a@b.com',
            phoneNumber: '123456789',
            photoURL: null,
            providerId: 'email',
            uid: 'Xp6wdnrJ8gS9rAZID3nUjrGAs5Z2',
            active: true,
            externalProvider: false,
            emailVerified: true,
            creationTime: Date.now().toString(),
            lastSignInTime: Date.now().toString(),
            isNew: false,
            operationType: 'login'
        });
    }

    updateCanView() {
        this.canView.next(sessionStorage.getItem('adminTestPass') === 'testaculous');
    }

    setAdminTestPass(password: string): void {
        sessionStorage.setItem('adminTestPass', password);
        this.updateCanView();
    }
}
