import { Component } from '@angular/core';
import { EnvironmentService } from './services/environment.service';
import { UserService } from './modules/user/user.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    version = '';
    canView = false;

    constructor(
        private envService: EnvironmentService,
        private userService: UserService
    ) {
        this.version = `Inimagident™ v${envService.version}`;
        userService.canView$.subscribe(canView => this.canView = canView);
    }
}
