import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { NgTrichotomyModule } from 'ng-trichotomy';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faTooth,
    faSearch,
    faUserCircle,
    faUser,
    faUserPlus,
    faUserCog,
    faCalendarAlt,
    faVenus,
    faMars,
    faGenderless,
    faTrash,
    faCloudUploadAlt
} from '@fortawesome/free-solid-svg-icons';

import {
    faImage,
    faImages
} from '@fortawesome/free-regular-svg-icons';

import { EnvironmentService } from './services/environment.service';

import { AppRoutingModule } from './app-routing.module';
import { DataModule } from './data/data.module';

import { HomeModule } from './modules/home/home.module';

import { AppComponent } from './app.component';
import { HeaderComponent } from './modules/home/header/header.component';
import { FooterComponent } from './modules/home/footer/footer.component';
import { MainComponent } from './modules/home/main/main.component';
import { AdminGateComponent } from './admin-gate/admin-gate.component';

library.add(
    faTooth,
    faSearch,
    faUserCircle,
    faUser,
    faUserCog,
    faUserPlus,
    faCalendarAlt,
    faVenus,
    faMars,
    faGenderless,
    faImage,
    faImages,
    faTrash,
    faCloudUploadAlt
);

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        NgTrichotomyModule,
        FontAwesomeModule,
        AppRoutingModule,
        HomeModule,
        DataModule
    ],
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        MainComponent,
        AdminGateComponent
    ],
    exports: [
        NgTrichotomyModule
    ],
    providers: [
        EnvironmentService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
